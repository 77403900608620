<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card flat max-width="1100" width="100%" class="mb-5">
        <v-toolbar flat class="mb-3">
          <h1 class="headline black--text">Messaging</h1>
          <v-spacer />
          <v-btn color="primary" depressed to="/patients/messaging/upload">New Messaging</v-btn>
        </v-toolbar>

        <v-card>
          <v-tabs v-model="tab" background-color="grey lighten-4">
            <v-tab to="/patients/messaging">All</v-tab>
            <v-tab>Item Two</v-tab>
          </v-tabs>

          <div v-if="isLoading" class="pa-5 text-center">
            <v-progress-circular width="2" indeterminate color="primary" />
          </div>
          <v-list v-if="!isLoading">
            <v-list-item v-for="item in messages" :key="item.id" :to="'messaging/' + item.title">
              <v-list-item-avatar>
                <v-img :src="item.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
                <v-list-item-subtitle>{{ item.subTitle }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-chip class="red--text" color="grey lighten-4">pendiente pago</v-chip>
              </v-list-item-icon>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
        <template v-if="totalResults && !isLoading">
          <div class="mt-3 text-center">
            <div class="mt-3 grey--text">
              Results {{ currentPage * perPage - perPage + 1 }} - {{ Math.min(currentPage * perPage, totalResults) }} of
              {{ totalResults }}
            </div>
            <v-pagination
              v-if="totalPages > 1"
              v-model="currentPage"
              :length="totalPages"
              text
              :total-visible="6"
              circle
              color="primary"
              @input="changePage"
            />
          </div>
        </template>
      </v-card>
    </v-row>
    <v-overlay :value="overlay">
      <v-btn icon @click="overlay = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-overlay>
  </v-container>
</template>
<script>
import Alerts from '@/mixins/Alerts';

export default {
  title: 'Messaging',
  mixins: [Alerts],
  data() {
    return {
      isLoading: false,
      overlay: false,
      tab: 2,
      currentPage: 1,
      perPage: 24,
      totalPages: null,
      totalResults: null,
      messages: [
        {
          icon: true,
          title: 'Jason Oner',
          subTitle: 'Nº Colegiado',
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        },
        {
          title: 'Travis Howard',
          subTitle: 'Nº Colegiado',

          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        },
        {
          title: 'Ali Connors',
          subTitle: 'Nº Colegiado',

          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        },
        {
          title: 'Cindy Baker',
          subTitle: 'Nº Colegiado',

          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        },
      ],
    };
  },
  mounted() {
    this.loadMessages();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.loadMessages();
    },
    loadMessages() {
      this.isLoading = true;
      this.$http
        .get('/patients/messages', {
          params: {
            offset: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
          },
        })
        .then(res => {
          this.messages = res.data;
          this.totalResults = res.data.metadata.total;
          this.totalPages = Math.ceil(this.totalResults / this.perPage);
          this.$scrollTo('#app');
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
